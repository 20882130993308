<template>
  <div class="admin-wrap">
    <a class="btn-new" href="/admin/treatments/0"></a>
    <AdminMenu active-item="treatments" />
    <div class="admin-main">
      <AdminHeader title="服務管理：療程" />
      <div class="service-course-list">
        <div class="site-search site-search--admin">
          <input type="text" id="inputSearch" v-model="keyword">
          <button>搜尋</button>
        </div>
        <table class="admin-table sortable">
          <thead>
            <tr>
              <th>編號</th>
              <th>療程</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in filteredDataList" :key="data">
              <td>{{data.id}}</td>
              <td>{{data.name}}</td>
              <td>
                <a class="admin-btn" :href="`/admin/treatments/${data.id}`">編輯</a>
                <a class="admin-btn" href="#" @click="showConfirmDelete(data.id)">刪除</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <confirm-delete-dialog :visible="isShowConfirmDelete" title="確定要刪除？" message="刪除後無法回復" @confirm="onDelete" @cancel="hideConfirmDelete" />
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import ConfirmDeleteDialog from '@/components/ConfirmDeleteDialog'
import { apiGetTreatments, apiDeleteTreatment } from '@/api/v1/admin'

export default {
  name: 'Treatments',
  components: { AdminMenu, AdminHeader, ConfirmDeleteDialog },
  computed: {
    filteredDataList () {
      return this.dataList.filter(data => data.name.includes(this.keyword))
    }
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      keyword: '',
      isShowConfirmDelete: false,
      dataList: [],
      selectedItemId: 0
    }
  },
  methods: {
    fetchData () {
      apiGetTreatments().then(data => {
        console.log(data)
        if (!data.error) {
          this.dataList = data
        }
      })
    },
    showConfirmDelete (itemId) {
      this.isShowConfirmDelete = true
      this.selectedItemId = itemId
    },
    hideConfirmDelete () {
      this.isShowConfirmDelete = false
      this.selectedItemId = 0
    },
    onDelete () {
      apiDeleteTreatment(this.selectedItemId).then(data => {
        if (data.error) {
          this.$swal('刪除失敗', data.message, 'warning')
        } else {
          this.fetchData()
        }
      }).catch(err => {
        this.$swal('刪除失敗', err.data.error.message, 'warning')
      }).finally(() => {
        this.hideConfirmDelete()
      })
    }
  }
}
</script>

<style>

</style>
